<template>
  <div class="container">
    <div class="search">
      <el-input
        placeholder="请输入机构名称至少8个字"
        v-model="searchTxt"
      ></el-input>
      <span @click="getdata">查询</span>
    </div>
    <div class="query-box">
      <h3><i class="line"></i><span>从业机构查询</span></h3>
      <div class="query-list">
        <div class="query-title">
            <span v-for="(item,index) in arrList" @click="filterSearchData(item,index)" :key="item.id" :class='{bg:item.id==isactive}'>
                <img v-if="item.id==isactive" src="@/assets/icon/select.png" alt="">
                <img v-else src="@/assets/icon/cancel.png" alt="">
                {{item.name}}
            </span>
        </div>
        <div class="query-bomb">
            <div class="query-result">
                <div v-for="(val,indexs) in this.institutionsList" :key="indexs">
                    <div class="result-title">
                        <img src="../assets/icon/query-icon.png" alt="">
                        <span>{{val.name}}</span>
                    </div>
                    <div class="result-text">
                        <div class="result-info">
                            <div>
                                机构类型：
                                <span v-show="val.organType == 1">食品复检机构</span>
                                <span v-show="val.organType == 2">国家质检中心</span>
                                <span v-show="val.organType == 3">强制性产品认证实验室</span>
                                <span v-show="val.organType == 4">强制性产品认证机构</span>
                                <span v-show="val.organType == 5">认证机构</span>
                                <span v-show="val.organType == 6">省级资质认定机构</span>
                                <span v-show="val.organType == 7">国家级资质认定机构</span>
                            </div>
                            <div v-if="val.organType == 4">
                                机构编号：
                                <span>{{val.OrganNo}}</span>
                            </div>
                            <div v-else>
                                证书编号：
                                <span>{{val.certNo}}</span>
                            </div>
                            <div>
                                到期日期：
                                <span>{{val.expireDate}}</span>
                            </div>
                        </div>
                        <div class="certificate-info">
                            <div v-show="val.organType == 7" class="unit-info">
                                认定部门：
                                <span>{{val.recognizeOrgan}}</span>
                            </div>
                            <div v-show="val.organType == 6 || val.organType == 1" :class="{'unit-style':val.organType == 6}">
                                颁证机构：
                                <span>{{val.recognizeOrgan}}</span>
                            </div>
                            <el-tooltip class="item" effect="dark" :content="val.recognizeTypeDomain" placement="top-start">
                                <div v-show="val.organType == 5" class="unit-info">
                                    认证领域：
                                    <span>{{val.recognizeTypeDomain}}</span>
                                </div>
                            </el-tooltip>
                            <div v-show="val.organType == 4" class="unit-info">
                                业务范围：
                                <span>{{val.businessScope}}</span>
                            </div>
                            <div v-show="val.organType == 3 || val.organType == 2" class="unit-info">
                                所属单位：
                                <span>{{val.unit}}</span>
                            </div>
                            <div v-show="val.organType == 6">
                                专业领域：
                                <span>{{val.profession}}</span>
                            </div> 
                            <div v-show="val.organType == 1">
                                认证对象：
                                <span>{{val.recognizeObject}}</span>
                            </div>  
                            <div class="status-info" v-show="val.organType == 1 || val.organType == 2 || val.organType == 5 || val.organType == 6 ||val.organType == 7">
                                证书状态：
                                <span v-show="val.certStatus==1" class="status effective">有效</span>
                                <span v-show="val.certStatus==2" class="status suspended">暂停</span>
                                <span v-show="val.certStatus==3" class="status undo">撤销</span>
                                <span v-show="val.certStatus==4" class="status cancellation">注销</span>
                                <span v-show="val.certStatus==5" class="status overdue">过期</span>
                            </div>
                            <div class="status-info" v-show="val.organType == 3 || val.organType == 4">
                                机构状态：
                                <span v-show="val.certStatus==1" class="status effective">有效</span>
                                <span v-show="val.certStatus==2" class="status suspended">暂停</span>
                                <span v-show="val.certStatus==3" class="status undo">撤销</span>
                                <span v-show="val.certStatus==4" class="status cancellation">注销</span>
                                <span v-show="val.certStatus==5" class="status overdue">过期</span>
                            </div>
                            <div class="details-btn" @click="lookinfo(val)">查看详情</div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="outcome" v-show="this.institutionsList.length<1">
          <p><span>未查到符合条件的信息</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
        arrList:[{
              name:"国家级资质认定机构",
              bool:false,
              id:7
          },{
              name:"省级资质认定机构",
              bool:false,
              id:6
          },{
              name:"认证机构",
              bool:false,
              id:5
          },{
              name:"强制性产品认证机构",
              bool:false,
              id:4
          },{
              name:"强制性产品认证实验室",
              bool:false,
              id:3
          },{
              name:"国家质检中心",
              bool:false,
              id:2
          },{
              name:"食品复检机构",
              bool:false,
              id:1
        }],
        isactive:null,
        searchTxt:'',
        institutionsList: []
    };
  },
  methods: {
    lookinfo(val){
        if(val.organType == 1){
            this.$router.push({ path: '/foodRecheck',query: { id: val.id } })
        }else if(val.organType == 2){
            this.$router.push({ path: '/countriesDetection',query: { id: val.id } })
        }else if(val.organType == 3){
            this.$router.push({ path: '/certifiedLaboratory',query: { id: val.id } })
        }else if(val.organType == 4){
            this.$router.push({ path: '/productCertification',query: { id: val.id } })
        }else if(val.organType == 5){
            this.$router.push({ path: '/certificationBody',query: { id: val.id } })
        }else if(val.organType == 6){
            this.$router.push({ path: '/provincialQualification',query: { id: val.id } })
        }else if(val.organType == 7){
            this.$router.push({ path: '/NationalQualification',query: { id: val.id } })
        }
    },
    filterSearchData(item){
        localStorage.setItem('organId',item.id)
        this.isactive = item.id;
        var urlData = "";
        if(this.searchTxt){
            urlData = "/rzgs/organs?name="+this.searchTxt+"&organType="+this.isactive;
            
        }else {
            urlData = "/rzgs/organs?organType="+this.isactive;
        }
        axios({
            method: "get",
            url:
            process.env.VUE_APP_BASE_URL + urlData
        }).then((response) => {
            if (response.data.status === 0) {
                this.institutionsList = response.data.data
            }
        })

    },
    getdata() {
        localStorage.setItem('valName',this.searchTxt)
        var urlStr = "";
        if(this.isactive){
            if(this.searchTxt){
                urlStr = "/rzgs/organs?name="+this.searchTxt+"&organType="+this.isactive;
            }else {
                urlStr = "/rzgs/organs?organType="+this.isactive;
            }
        }else if(this.searchTxt){
            if(this.isactive){
                urlStr = "/rzgs/organs?name="+this.searchTxt+"&organType="+this.isactive;
            }else {
                urlStr = "/rzgs/organs?name="+this.searchTxt;
            }
        }else if(!this.isactive && !this.searchTxt){
            urlStr = "/rzgs/organs";
        }
        axios({
            method: "get",
            url:
            process.env.VUE_APP_BASE_URL + urlStr
        }).then((response) => {
            if (response.data.status === 0) {
                this.institutionsList = response.data.data
            }
        });
    }
  },
    mounted() {
        if(localStorage.getItem('valName')){
            this.searchTxt = localStorage.getItem('valName')
        }
        this.arrList.forEach(item => {
            if(item.id == localStorage.getItem('organId')){
                this.isactive = item.id;
            }
        })
        this.getdata();
    }
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  .search {
    position: relative;
    width: 826px;
    margin: 32px auto 40px;
    height: 48px;
    line-height: 48px;
    span {
      display: inline-block;
      color: #fff;
      height: 48px;
      line-height: 48px;
      width: 105px;
      background-color: #4882F8;
      position: absolute;
      right: -2px;
      bottom: 0;
      letter-spacing: 6px;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
    }
  }
  .el-input {
    width: 100%;
    text-align: left;
    height: 48px;
    line-height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
  }

  /deep/.el-input__inner {
    line-height: 48px;
    height: 48px;
    border: 0;
    outline: 0;
    font-size: 16px;
    color: #333;
  }
  .query-box{
    width: 1200px;
    margin:0px auto 96px auto;
  }
  .query-box>h3{
      width: 100%;
      font-size: 24px;
      font-weight: 500;
      color: #323233;
  }
  .query-box>h3>i{
      display: inline-block;
      width: 5px;
      height: 23px;
      background: linear-gradient(180deg, #74B7FF 0%, #4882F8 100%);
      opacity: 1;
      border-radius: 4px;
      margin-right:8px;
      vertical-align: sub;
  }
  .query-box>h3>span{
      display: inline-block;
      vertical-align: middle;
  }
  .query-box .query-list{
      background: #fff;
      margin-top:24px;
      padding:0px 24px 32px 24px;
  }
  .outcome{
    margin:56px auto 26px auto;
    p{
      position: relative;
      width: 360px;
      border-bottom:1px solid #323233;
      margin:0 auto;
      span{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-10px;
        margin-left:-99px;
        width: 198px;
        background: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #323233;
      }
    }
  }
  .query-box .query-list .query-title{
      padding-bottom:13px;
      border-bottom:1px solid #DCDEE0;
      font-size: 0;
  }
  .query-box .query-list .query-title>span{
      display: inline-block;
      padding:13px 16px;
      margin-right:16px;
      margin-top:24px;
      font-size: 16px;
      font-weight: 400;
      color: #323233;
      border: 1px solid #DCDEE0;
      cursor: pointer;
  }
  .query-box .query-list .query-title>span>img{
      width: 18px;
      height: 18px;
      background-size: 100% 100%;
      vertical-align: middle;
  } 
  .bg{
      background: #DCDEE0;
      color:#4882F8 !important;
  }
  .query-box .query-list .query-bomb{
      width: 100%;
  }
  .query-bomb .query-result .result-title{
      margin-top:40px;
  }
  .query-bomb .query-result .result-title:nth-child(1){
      margin-top:32px;
  }
  .query-bomb .query-result .result-title>img{
      width: 28px;
      height: 28px;
      margin-right:7px;
      vertical-align: middle;
  }
  .query-bomb .query-result .result-title>span{
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      vertical-align: middle;
  }
  .query-bomb .query-result .result-text{
      width: 1152px;
      height: 116px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
      opacity: 1;
      margin-top:16px;
  }
  .query-bomb .query-result .result-text:hover{
      background-color: #F2F3F5;
      box-shadow: none;
  }
  .query-bomb .query-result .result-text>div{
      padding-top:24px;
  }
  .query-bomb .query-result .result-text>div>div{
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #969799;
      padding-left:16px;
  }
  .query-bomb .query-result .result-text>div>div>span{
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #323233;
  }
  .query-bomb .query-result .result-text>div>div .status{
      padding:2px 5px;
      text-align: center;
      border-radius: 2px;
      font-size: 12px !important;
      color:#fff;
      vertical-align: top;
  }
  .effective{
      background: #00B01B;
  }
  .suspended{
      background: #BFCA00;
  }
  .undo{
      background: #B00004;
  }
  .cancellation{
      background: #FF0000;
  }
  .overdue{
      background: #808080;
  }
  .query-result .result-text .result-info>div{
      width: 29%;
  }
  .result-text .certificate-info{
      position: relative;
  }
  .query-result .result-text .certificate-info>div{
      width: 29%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .result-text .certificate-info .status-info{
      width: 26% !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .result-text .certificate-info .unit-info{
      width: 59.5% !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .result-text .certificate-info .unit-style>span{
      color:#4882F8 !important;
  }
  .query-result .result-text .certificate-info .details-btn{
      position: absolute;
      bottom:6px;
      right:42px;
      width: 96px;
      height: 40px;
      padding-left:0;
      line-height: 40px;
      text-align: center;
      background: #4882F8;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
  }
}
</style>
<style>
.el-tooltip__popper {
    max-width: 36%;
}
</style>